'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_APP_USER = undefined;

var _templateObject = _taggedTemplateLiteral(['\n\tquery ($id: ID!) {\n\t\tappUser(id: $id) {\n\t\t\tid\n\t\t\tfirstName\n\t\t\tfullName\n\t\t\tlastName\n\t\t\tdateOfBirth\n\t\t\tcreatedOn\n\t\t\tuserDataCbd {\n\t\t\t\ttrainingMandatory2016\n\t\t\t\ttrainingMandatory2017\n\t\t\t\ttrainingMandatory2018\n\t\t\t\ttrainingMandatory2019\n\t\t\t\ttrainingMandatory2020\n\t\t\t\ttrainingMandatory2021\n\t\t\t\ttrainingMandatory2022\n\t\t\t\ttrainingMandatory2023\n\t\t\t\ttrainingMandatory2024\n\t\t\t\ttrainingMandatory2025\n\t\t\t\tfunction2016\n\t\t\t\tfunction2017\n\t\t\t\tfunction2018\n\t\t\t\tfunction2019\n\t\t\t\tfunction2020\n\t\t\t\tfunction2021\n\t\t\t\tfunction2022\n\t\t\t\tfunction2023\n\t\t\t\tfunction2024\n\t\t\t\tfunction2025\n\t\t\t\tfunction\n\t\t\t}\n\t\t}\n\t}\n'], ['\n\tquery ($id: ID!) {\n\t\tappUser(id: $id) {\n\t\t\tid\n\t\t\tfirstName\n\t\t\tfullName\n\t\t\tlastName\n\t\t\tdateOfBirth\n\t\t\tcreatedOn\n\t\t\tuserDataCbd {\n\t\t\t\ttrainingMandatory2016\n\t\t\t\ttrainingMandatory2017\n\t\t\t\ttrainingMandatory2018\n\t\t\t\ttrainingMandatory2019\n\t\t\t\ttrainingMandatory2020\n\t\t\t\ttrainingMandatory2021\n\t\t\t\ttrainingMandatory2022\n\t\t\t\ttrainingMandatory2023\n\t\t\t\ttrainingMandatory2024\n\t\t\t\ttrainingMandatory2025\n\t\t\t\tfunction2016\n\t\t\t\tfunction2017\n\t\t\t\tfunction2018\n\t\t\t\tfunction2019\n\t\t\t\tfunction2020\n\t\t\t\tfunction2021\n\t\t\t\tfunction2022\n\t\t\t\tfunction2023\n\t\t\t\tfunction2024\n\t\t\t\tfunction2025\n\t\t\t\tfunction\n\t\t\t}\n\t\t}\n\t}\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n\tquery ($program: ID!, $user: ID!) {\n\t\tboxResults(program: $program, user: $user) {\n\t\t\tuser {\n\t\t\t\tid\n\t\t\t}\n\t\t\tid\n\t\t\tlastModifiedOn\n\t\t\tfinishedOn\n\t\t\tfinished\n\t\t\trating\n\t\t\tprogress\n\t\t\tcertificatePdfUrl\n\t\t\tbox {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tmandatory\n\t\t\t\tyearProgress\n\t\t\t}\n\t\t\ttext\n\t\t}\n\t}\n'], ['\n\tquery ($program: ID!, $user: ID!) {\n\t\tboxResults(program: $program, user: $user) {\n\t\t\tuser {\n\t\t\t\tid\n\t\t\t}\n\t\t\tid\n\t\t\tlastModifiedOn\n\t\t\tfinishedOn\n\t\t\tfinished\n\t\t\trating\n\t\t\tprogress\n\t\t\tcertificatePdfUrl\n\t\t\tbox {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tmandatory\n\t\t\t\tyearProgress\n\t\t\t}\n\t\t\ttext\n\t\t}\n\t}\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_APP_USER = exports.GQL_FETCH_APP_USER = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_BOX_RESULTS = (0, _graphqlTag2.default)(_templateObject2);